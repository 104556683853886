<template>
  <div id="payPwd">
    <el-input
      ref="pwd"
      v-model="msg"
      :maxlength="6"
      clearable
      auto-complete="off"
      type="password"
      @blur="inputBlur"
      style="position: absolute; z-index: -1; left: -10000%; opacity: 0"
    >
    </el-input>
    <ul class="pwd-wrap" @click="focus">
      <li :class="{ active: isfocus && msgLength < 1 }">
        <i v-if="msgLength > 0"></i>
      </li>
      <li :class="{ active: isfocus && msgLength == 1 }">
        <i v-if="msgLength > 1"></i>
      </li>
      <li :class="{ active: isfocus && msgLength == 2 }">
        <i v-if="msgLength > 2"></i>
      </li>
      <li :class="{ active: isfocus && msgLength == 3 }">
        <i v-if="msgLength > 3"></i>
      </li>
      <li :class="{ active: isfocus && msgLength == 4 }">
        <i v-if="msgLength > 4"></i>
      </li>
      <li :class="{ active: isfocus && msgLength == 5 }">
        <i v-if="msgLength > 5"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      msg: "",
      msgLength: 0,
      isfocus: false,
    };
  },
  mounted() {
    // this.focus();
  },
  props:{
    psd:{
      type: String,
      default: '',
    },
  },
  watch: {
    msg(curVal) {
      if (/[^\d]/g.test(curVal)) {
        this.msg = this.msg.replace(/[^\d]/g, "");
      } else {
        this.msgLength = curVal.length;
        this.$emit("update:psd", curVal);
      }
    },
  },
  methods: {
    inputBlur() {
      // this.isfocus = false;
      // this.focus();
    },
    focus() {
      this.isfocus = true;
      this.$refs.pwd.focus();
    },
  },
};
</script>


<style scoped>
#payPwd .pwd-wrap {
  width: 252px;
  height: 42px;
  padding-bottom: 1px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  cursor: pointer;
}

.pwd-wrap li {
  list-style-type: none;
  text-align: center;
  line-height: 44px;
  -webkit-box-flex: 1;
  flex: 1;
  -webkit-flex: 1;
  border-right: 1px solid #ddd;
}

.pwd-wrap li:last-child {
  border-right: 0;
}
.active::before {
  content: "";
  display: inline-block;
  background: #222;
  width: 2px;
  height: 15px;
  animation: mymove 1s infinite;
}
@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.pwd-wrap li i {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #000;
  display: inline-block;
}
</style>
